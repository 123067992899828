import React from 'react';
import { useNavigate } from 'react-router-dom';

function ButtonLink({ href, label, target }) {
    const navigate = useNavigate();

    const handleClick = () => {
        if (href.startsWith('/')) {
            navigate(href);
        } else {
            window.open(href, target || '_self');
        }
    };

    return <button
        className="text-white px-6 py-2 rounded focus-ring bg-primary"
        onClick={handleClick}>
        {label}
    </button>;
}

export default ButtonLink;