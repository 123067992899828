import React from 'react';
import UserNewForm from './UserNewForm.js';
import PageTitle from 'components/PageTitle.js';

function UserNew() {
    return (
        <div className="max-w-lg mx-auto p-4">
            <PageTitle title="Sign Up" />
            <p className="mb-6">The club is still on the launch pad. This will put you on our email list.</p>
            <UserNewForm />
        </div>
    );
}

export default UserNew;
