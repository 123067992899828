import React from 'react';
import ProfileEditForm from './ProfileEditForm.js';
import PageTitle from 'components/PageTitle.js';

function ProfileEdit() {
    return (
        <div>
            <PageTitle title="Edit Profile" />
            <ProfileEditForm />
        </div>
    );
}

export default ProfileEdit;
