import React from 'react';
import HomeMission from './HomeMission.js';
import ButtonLink from 'components/ButtonLink.js';

function Home() {
    return (
        <div className="flex flex-col md:flex-row items-center justify-center">
            <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <img src="/images/aristillus.webp" alt="Aristillus"
                     className="rounded-lg shadow-lg"/>
            </div>

            <div className="w-full md:w-1/2 md:pl-8">
                <h2 className="text-2xl font-semibold mb-4">Join the Network</h2>
                <HomeMission />
                <ButtonLink href="/join" label="Sign Up" />
            </div>
        </div>
    );
}

export default Home;
