import React, { useState } from 'react';

function HomeMission() {
    const [isToggled, setIsToggled] = useState(false);

    const toggleText = () => {
        setIsToggled(!isToggled);
    };

    return (
        <div>
            { !isToggled && <div id="short-description" className="mb-4">
                <p className="mb-4">A venture for people with pine tree hearts and moon landing brains.{" "}
                    <button onClick={toggleText} className="button-link text-primary">Read More</button>
                </p>
            </div> }

            { isToggled && <div id="full-description" className="mb-4">
                <p className="mb-4">Three things have become clear since the dawn of the 21st century:</p>
                <ol className="list-decimal mb-4 mx-10">
                    <li className="mb-4">The western neoliberal consensus is exhausted, choking on its own waste products,
                        unable to answer fundamental questions, endlessly pounding on a podium shouting tired old irrelevant
                        slogans and sending jack booted thugs to intimidate anyone who points and laughs at the emperor
                        without clothes.
                    </li>
                    <li className="mb-4">Defending boundaries is a precondition to liberty. Gardens need walls. Walls need
                        defenders. If you let the world into your private club, you no longer have a private club - you have
                        the entire world voting on how to replace you and the things you love with the lowest common
                        denominator.
                    </li>
                    <li className="mb-4">People are distributed, online, and networked.</li>
                </ol>
                <p className="mb-4">Neoliberalism is dead.</p>
                <p className="mb-4">What comes next?</p>
                <p className="mb-4">Some say anarcho-capitalism. Others say neoreaction. Others say the network
                    state.</p>
                <p className="mb-4">The answer is all of those things, and none of them.</p>
                <button onClick={toggleText} className="button-link text-primary">Read Less</button>
            </div> }
        </div>
    );
}

export default HomeMission;