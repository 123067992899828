import React from 'react';
import PageTitle from 'components/PageTitle.js';
import useScript from 'effects/useScript.js';

function MembershipNew() {
    const handleClick = async (e) => {
        console.log('key: ' + process.env.REACT_APP_STRIPE_CLIENT_KEY);

        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_CLIENT_KEY);

        fetch('/create-checkout-session', {
            method: 'POST',
        })
            .then(response => response.json())
            .then(session => stripe.redirectToCheckout({sessionId: session.id}));
    }

    return (
        <div className="max-w-lg mx-auto p-4">
            <PageTitle title="Become a Member" />
            <p className="mb-4">Clicking below will take you to Stripe to complete checkout. You should only do this if
                you have been explicitly pre-approved to do so.</p>
            <button onClick={handleClick}
                    className="text-white px-6 py-2 rounded focus:outline-none focus:ring-2 bg-primary">Subscribe
            </button>
        </div>
    );
}

export default MembershipNew;
