import React, { useState } from 'react';
import FormLabel from 'components/FormLabel.js';
import Alert, { ALERT_TYPES }  from 'components/Alert.js';

function ProfileEditForm() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(apiUrl + '/api/v1/profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name: name,
                    address: address
                })
            });

            const data = await response.json();

            if (response.ok && !data.error) {
                setAlertMessage('Your profile has been updated.');
                setAlertType(ALERT_TYPES.SUCCESS);
            } else {
                setAlertMessage(data.error);
                setAlertType(ALERT_TYPES.ERROR);
            }
        } catch (error) {
            setAlertMessage('An error occurred. Please try again later.');
            setAlertType(ALERT_TYPES.ERROR);
        }

    }
        return (
            <form className="mt-6" onSubmit={handleSubmit}>
                <div className="mb-4">
                    <FormLabel forAttr="email" label="Email" />
                    <input type="email" value={name} name="email" placeholder="Enter your email" required
                           onChange={(e) => setName(e.target.value)}
                           className="flex-grow px-4 py-2 rounded focus-ring"/>
                </div>
                <div className="mb-4">
                    <FormLabel forAttr="address" label="Address" />
                    <input type="text" value={address} name="address" placeholder="Enter your address" required
                           onChange={(e) => setAddress(e.target.value)}
                           className="flex-grow px-4 py-2 rounded focus-ring"/>
                </div>
                <div className="mb-4">
                    <button type="submit"
                            className="text-white px-6 py-2 rounded-r-lg focus:outline-none focus:ring-2 bg-primary">Launch
                    </button>
                </div>
                {alertMessage && <Alert message={alertMessage} type={alertType} /> }
            </form>
        );
}

export default ProfileEditForm;