import React from 'react'
import PageTitle from "components/PageTitle.js"

function NotFound404() {
    return (
        <div>
            <PageTitle title="Page Not Found" />
            <p>Look, we've got a lot to build. We're not going to make an amazing 404 page to start.</p>
        </div>
    );
}

export default NotFound404;
