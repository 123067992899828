import React from 'react';

export const ALERT_TYPES = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
};

function Alert({ type = ALERT_TYPES.INFO, message }) {
    const alertStyles = {
        'success' : 'bg-green-100 border border-green-400 text-green-700',
        'error' : 'bg-red-100 border border-red-400 text-red-700',
        'warning' : 'bg-yellow-100 border border-yellow-400 text-yellow-700',
        'info' : 'bg-blue-100 border border-blue-400 text-blue-700',
    };

    return <div className={`${alertStyles[type]} px-4 py-3 rounded`} role="alert">
        {message}
    </div>

}

export default Alert;