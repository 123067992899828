import React from 'react';

function LoadingIndicator({ message = 'Loading...' }) {
    return (
        <div className="flex flex-col items-center justify-center space-y-2">
            <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
            <p className="text-gray-700 text-sm font-semibold">{message}</p>
        </div>
    );
}

export default LoadingIndicator;