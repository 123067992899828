import React from 'react';
import { Outlet } from 'react-router-dom';

function LayoutStandard() {
    return (
        <div className="container mx-auto px-4 py-8">

            <header className="text-center mb-8">
                <a href="/" className="text-4xl font-bold title-logo">Aristillus</a>
            </header>

            <Outlet />
        </div>
    );
}

export default LayoutStandard;
