import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import PageTitle from 'components/PageTitle.js';
import Alert, {ALERT_TYPES} from 'components/Alert.js';

function UserVerifyEmail() {
    const { token } = useParams();
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    useEffect(() => {
        const confirmEmail = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + `/api/v1/users/verify`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token }), // Send token to the server
                });

                const data = await response.json();

                if (response.ok && !data.error) {
                    setAlertMessage('Email confirmed successfully.');
                    setAlertType(ALERT_TYPES.SUCCESS);
                } else {
                    setAlertMessage(data.error);
                    setAlertType(ALERT_TYPES.ERROR);
                }
            } catch (error) {
                setAlertMessage('Error attempting to confirm email. Please try again, then contact support.');
                setAlertType(ALERT_TYPES.ERROR);
            }
        };

        confirmEmail();
    }, [token]); // Runs only once when the component mounts

    return (
        <div className="max-w-lg mx-auto p-4">
            <PageTitle title="Email Verification" />
            {alertMessage && <Alert message={alertMessage} type={alertType} /> }
            { !alertMessage && <LoadingIndicator message="Confirming email" /> }
        </div>
    );
}

export default UserVerifyEmail;
